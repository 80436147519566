export const colours = [
  '#FF5733', // Red-Orange
  '#33FF57', // Green
  '#3357FF', // Blue
  '#FF33A1', // Pink
  '#33FFA1', // Light Green
  '#A133FF', // Purple
  '#FF8C33', // Orange
  '#33FFF0', // Cyan
  '#FFA133', // Light Orange
  '#33A1FF', // Light Blue
  '#A1FF33', // Lime
  '#FF33FF', // Magenta
  '#8C33FF', // Violet
  '#FFD133', // Gold
  '#33D1FF', // Sky Blue
  '#FF3333', // Red
  '#57FF33', // Light Lime
  '#33FFD1', // Aquamarine
  '#FF5733', // Coral
  '#33FF8C', // Mint
];
