export function secondsToHours(seconds: number): number {
  // is to 1 dp
  return seconds / 3600;
}

export function hoursToGeneralTimeUnit(hours: number): string {
  if (hours >= 1) {
    return `${toFixed(hours, 0)} hr${hours === 1 ? '' : 's'}`;
  }
  if (hours >= 1 / 60) {
    return `${toFixed(hours * 60, 0)} min${hours === 1 / 60 ? '' : 's'}`;
  }
  return `${toFixed(hours * 3600, 0)} sec${hours === 1 / 3600 ? '' : 's'}`;
}
function toFixed(num: number, dp: number): number {
  return Number(num.toFixed(dp));
}

export function secondsToGeneralTimeUnit(seconds: number): string {
  return hoursToGeneralTimeUnit(secondsToHours(seconds));
}
