import type { LoaderFunctionArgs } from '@remix-run/node';
import { json, useLoaderData } from '@remix-run/react';
import { goalController } from '~/backend/controllers/GoalController';
import Dashboard from '~/my-components/Dashboard';
import { DashboardData } from '~/types';
import { getUserId } from '~/utils/auth.server';
import { localDate, setHoursMinutesSecondsToZero } from '~/utils/dateUtils';
import { dummyDashboardData } from '~/utils/dummyData';
import { getTimeZone } from '~/utils/request-info';

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const userId = await getUserId(request, context);
  const timeZone = getTimeZone(request);
  // start date is format 05/
  const today = setHoursMinutesSecondsToZero(new Date());
  const oneWeekAgo = setHoursMinutesSecondsToZero(new Date());
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  const startDate = localDate(oneWeekAgo, timeZone);
  const endDate = localDate(today, timeZone);
  const data: DashboardData = userId
    ? {
        startDate,
        endDate,
        goals: await goalController.getUserTimeUsage(userId, oneWeekAgo, today, request, context),
        dailyUsage: await goalController.getUserDailyUsage(userId, oneWeekAgo, today, request, context),
      }
    : dummyDashboardData;
  return json(data);
};

export default function Home() {
  const data = useLoaderData<typeof loader>();
  return <Dashboard data={data} />;
}
