import { parseWithZod } from '@conform-to/zod';
import { ActionFunctionArgs } from '@remix-run/cloudflare';
import { json, useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { Input } from '~/components/ui/input';
import { createStorage, getUserId, getUserSession, updateSessionAndReturn } from '~/utils/auth.server';
import { getPrisma } from '~/utils/db.server';
import { isNullOrUndefined } from '~/utils/typecheck';
import { useRootLoaderData } from '~/utils/useRootLoaderData';

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const userId = await getUserId(request, context);
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema: Schema });
  if (submission.status !== 'success') {
    return json({ result: submission.reply() }, { status: submission.status === 'error' ? 400 : 200 });
  }
  const hourlyRate = submission.value.hourlyRate;
  if (!userId) {
    const storage = createStorage(context);
    const session = await getUserSession(request, context);
    return updateSessionAndReturn(storage, session, it => it.set('hourlyRate', hourlyRate), json({ hourlyRate }));
  }
  const prisma = getPrisma({ context });
  await prisma.user.update({
    where: { id: userId },
    data: {
      hourlyRate: submission.value.hourlyRate,
    },
  });
  return json({ hourlyRate });
};
const Schema = z.object({
  hourlyRate: z.coerce.number().positive().max(100000),
});

function useHourlyRateFetcher() {
  return useFetcher<typeof action>({ key: 'hourlyRateSubmit' });
}
export function useHourlyRate() {
  const fetcher = useHourlyRateFetcher();
  const user = useRootLoaderData()?.user;
  const savedRate = user?.hourlyRate?.toString() ?? '80';

  const formData = fetcher.formData;
  if (!isNullOrUndefined(formData)) {
    const submission = parseWithZod(formData, { schema: Schema });
    if (submission.status === 'success') {
      return submission.value.hourlyRate.toString();
    }
  }
  return savedRate;
}

export function HourlyRateButton() {
  const fetcher = useHourlyRateFetcher();
  const user = useRootLoaderData()?.user;
  const savedHourlyRate = user?.hourlyRate?.toString();
  const [hourlyRate, setHourlyRate] = useState(savedHourlyRate ?? '80');

  useEffect(() => {
    if (!isNullOrUndefined(user) && !isNullOrUndefined(hourlyRate) && hourlyRate.length > 0) {
      const formData = new FormData();
      formData.set('hourlyRate', hourlyRate);
      fetcher.submit({ hourlyRate: hourlyRate }, { action: '/resources/hourly-rate-button', method: 'post' });
    }
  }, [hourlyRate]);

  return (
    <div className="flex items-center gap-2 flex-1 mb-2">
      <span>Hourly Rate:</span>
      <Input
        type="text"
        inputMode="numeric"
        value={hourlyRate}
        onChange={e => setHourlyRate(e.target.value)}
        className="flex-grow w-auto"
      />
    </div>
  );
}
