import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { DailyUsageData, Site } from '~/types';
import { colours } from '~/utils/colours';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = {
  labels: ['2024-06-12', '2024-06-13', '2024-06-14', '2024-06-15', '2024-06-16', '2024-06-17'],
  datasets: [
    {
      label: 'Reddit',
      data: [30, 25, 45, 50, 20, 10],
      backgroundColor: 'red',
    },
    {
      label: 'Twitter',
      data: [20, 35, 30, 25, 40, 50],
      backgroundColor: 'blue',
    },
    {
      label: 'Facebook',
      data: [10, 15, 5, 10, 15, 20],
      backgroundColor: 'green',
    },
  ],
};

export function DailyUsageChart({ data }: { data: DailyUsageData }) {
  const chartData = useMemo(() => {
    const allData = data.usages.reduce(
      (acc, { date, site, timeSeconds }) => {
        acc.dates.add(date);
        const data = { date, timeSeconds };
        const key = site.id;
        if (acc.sites[key]) {
          acc.sites[key].data.push(data);
        } else {
          acc.sites[key] = {
            site,
            data: [data],
            backgroundColor: colours[Object.keys(acc.sites).length],
          };
        }
        return acc;
      },
      {
        dates: new Set<string>(),
        sites: {} as Record<string, { site: Site; data: { date: string; timeSeconds: number }[]; backgroundColor: string }>,
      },
    );
    const dates = Array.from(allData.dates).sort();
    Object.values(allData.sites).forEach(({ data }) => data.sort((a, b) => a.date.localeCompare(b.date)));
    dates.forEach((date, i) => {
      Object.values(allData.sites).forEach(site => {
        if (site.data[i]?.date !== date) {
          site.data.splice(i, 0, { date, timeSeconds: 0 });
        }
      });
    });
    const datasets = Object.values(allData.sites).map(({ site, backgroundColor, data }) => ({
      label: site.url,
      backgroundColor,
      data: data.map(it => (it.timeSeconds / 3600).toFixed(1)),
    }));

    return {
      labels: dates,
      datasets,
    };
  }, [data]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Daily Website Usage',
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: '#f3f4f6',
        },
        title: {
          display: true,
          text: 'Hours',
        },
      },
    },
  };

  return (
    <div className="h-full w-full">
      <Bar data={chartData} options={options} />
    </div>
  );
}
