/**
 * v0 by Vercel.
 * @see https://v0.dev/t/a7ztOAe0e66
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Card, CardContent, CardHeader, CardTitle } from '~/components/ui/card';
import { Progress } from '~/components/ui/progress';

import { Link } from '@remix-run/react';
import { DailyUsageChart } from '~/my-components/DailyUsageChart';
import { HourlyRateButton, useHourlyRate } from '~/routes/resources.hourly-rate-button';
import { DashboardData } from '~/types';
import { routes } from '~/utils/routes';
import { hoursToGeneralTimeUnit, secondsToGeneralTimeUnit } from '~/utils/typeUtils';

export default function Dashboard({ data }: { data: DashboardData }) {
  const hourlyRate = useHourlyRate();
  return (
    <div className="max-w-6xl mx-auto grid gap-8">
      <div>
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <p className="text-gray-500 dark:text-gray-400">Track and manage your web usage.</p>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Weekly Goals</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-1 flex-col gap-4">
              {data.goals.length === 0 ? (
                <div className="flex flex-col  justify-center ">
                  <p className="text-gray-500 dark:text-gray-400">No goals set.</p>
                </div>
              ) : (
                data.goals.map(({ goal }, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <img
                        src={goal.site.faviconUrl ?? placeHolderFaviconUrl}
                        alt={`${goal.site.url} logo`}
                        className="w-6 h-6"
                      />
                      <span>{formatUrl(goal.site.url)}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold">{hoursToGeneralTimeUnit(goal.goalHours)}/week</span>
                    </div>
                  </div>
                ))
              )}
              <div className="flex-1" />
              <Link to={routes.newGoal} className="underline mt-2 text-center self-center">
                New goal
              </Link>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Time Spent</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              {data.goals.map(({ goal, timeSeconds }, index) => {
                const progress = (timeSeconds / 3600 / goal.goalHours) * 100;
                return (
                  <div key={goal.site.url}>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <img
                          src={goal.site.faviconUrl ?? placeHolderFaviconUrl}
                          alt={`${goal.site.url} logo`}
                          className="w-6 h-6"
                        />
                        <span>{formatUrl(goal.site.url)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="font-semibold">{secondsToGeneralTimeUnit(timeSeconds)}</span>
                        <span className="text-gray-500 dark:text-gray-400"> this week</span>
                      </div>
                    </div>
                    <Progress color={getProgressColor(progress)} value={progress} />
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Weekly Trends</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="aspect-[4/3]">
              <DailyUsageChart data={data.dailyUsage} />
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Dollars Spent</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div>
                <div className="flex items-center justify-between mb-2">
                  <HourlyRateButton />
                </div>
                <div className="grid gap-4">
                  {data.goals.map(({ goal, timeSeconds }, index) => (
                    <div key={goal.site.url}>
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                          <img
                            src={goal.site.faviconUrl ?? placeHolderFaviconUrl}
                            alt={`${goal.site.url} logo`}
                            className="w-6 h-6"
                          />
                          <span>{formatUrl(goal.site.url)}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">${((timeSeconds * Number(hourlyRate)) / 3600).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

const placeHolderFaviconUrl = 'https://emojings.com/wp-content/uploads/2021/09/question-mark-3.png';

function getProgressColor(progress: number) {
  if (progress >= 90) {
    return '#FF0000';
  } else if (progress >= 75) {
    return '#FF4500';
  } else if (progress >= 60) {
    return '#FFA500';
  } else if (progress >= 45) {
    return '#FFD700';
  } else if (progress >= 30) {
    return '#FFFF00';
  } else if (progress >= 15) {
    return '#ADFF2F';
  } else {
    return '#008000';
  }
}
function formatUrl(url: string) {
  // remove http, https, www, .com
  return url.replace(/(https?:\/\/)?(www\.)?|\.com/g, '');
}
